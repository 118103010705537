





















































import { Component, Mixins } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";
import IAskuePointsByObject from "@/models/consumption-table/AskuePointsByObject.interface";
import IChosenAskuePointProps from "@/models/consumption-table/ChosenAskuePointProps.interface";
import IConsumptionByHour from "@/models/consumption-table-by-point/ConsumptionByHour.interface";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import ConsumptionTable from "@/components/hourly-consumption-view/ConsumptionTable.component.vue";
import ConsumptionTableByPoint from "@/components/hourly-consumption-view/ConsumptionTableByPoint.component.vue";
import DocExchangeDialog from "@/components/hourly-consumption-view/DocExchangeDialog.component.vue";
import InvisibleWall from "@/components/InvisibleWall.vue";
import Page from "@/components/for-page-grid/Page.vue";
import XNotice from "@/components/hoc/SimpleNotice.vue";
import XSlider from "@/components/SimpleSlider.vue";

const showDialog = { upload: false, slider: false, loader: false };

@Component({
  components: {
    ConsumptionTable,
    ConsumptionTableByPoint,
    DocExchangeDialog,
    InvisibleWall,
    Page,
    XNotice,
    XSlider,
  },
  computed: {
    ...mapState({ hourlyConsumption: "hourlyConsumption" }),
    ...mapGetters("hourlyConsumption", {
      sortedAskueByObject: "sortedAskueByObject",
    }),
    ...mapGetters("contract", { contractId: "id" }),
  }
})
class HourlyConsumption extends Mixins(AppApiMixin) {
  [x: string]: any;

  data() {
    const chosenPointProps: IChosenAskuePointProps = {
      id: 0,
      level: 0,
      mode: 0,
      name: "",
    };

    return {
      chosenPointProps,
      showDialog: { ...showDialog },
      showNotice: false,
      showWall: false,
    };
  }

  dateUpdateHandle(date: string){
    if(date){
      this.getAskueObjects(this.contractId, date);
    }
  }

  public get protectedSortedAskueByObject(): IAskuePointsByObject[] {
    return this.sortedAskueByObject || [];
  }

  public get protectedConsumptionTablesByPoint(): Array<{
    caption: string;
    items: IConsumptionByHour[];
  }> {
    const { hourlyFact, hourlyPlan } = this.hourlyConsumption;

    return [
      { caption: "Плановое потребление", items: hourlyPlan || [] },
      { caption: "Фактическое потребление", items: hourlyFact || [] },
    ];
  }

  public created() {
    if(!this.sortedAskueByObject || this.sortedAskueByObject.length == 0){
      this.getAskueObjects(this.contractId);
    }
  }

  public closeAllDialogs() {
    this.showDialog = { ...showDialog };
  }
}

export default HourlyConsumption;
