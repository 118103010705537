var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',[_vm._l((_vm.protectedSortedAskueByObject),function(pointsByObject,i){return _c('ConsumptionTable',{key:i,attrs:{"pointsByObject":pointsByObject,"point":_vm.chosenPointProps,"dialogTypeUpload":_vm.showDialog.upload,"dialogTypeSlider":_vm.showDialog.slider,"dialogLoader":_vm.showDialog.loader,"wall":_vm.showWall},on:{"update:point":function($event){_vm.chosenPointProps=$event},"update:dialogTypeUpload":function($event){return _vm.$set(_vm.showDialog, "upload", $event)},"update:dialog-type-upload":function($event){return _vm.$set(_vm.showDialog, "upload", $event)},"update:dialogTypeSlider":function($event){return _vm.$set(_vm.showDialog, "slider", $event)},"update:dialog-type-slider":function($event){return _vm.$set(_vm.showDialog, "slider", $event)},"update:dialogLoader":function($event){return _vm.$set(_vm.showDialog, "loader", $event)},"update:dialog-loader":function($event){return _vm.$set(_vm.showDialog, "loader", $event)},"update:wall":function($event){_vm.showWall=$event},"update:date":_vm.dateUpdateHandle}})}),_c('DocExchangeDialog',{attrs:{"showDialog":_vm.showDialog.upload,"pointProps":_vm.chosenPointProps},on:{"agree":function () {
        _vm.closeAllDialogs();
        this$1.getAskueObjects(this$1.contractId);
        _vm.showNotice = !_vm.showNotice;
      },"disagree":_vm.closeAllDialogs}}),_c('XSlider',{attrs:{"show":_vm.showDialog.slider,"showPreloader":_vm.showDialog.loader,"title":("Объект: " + (_vm.chosenPointProps.name)),"containerModClass":"split-container_width_max","boxModClass":"split-container__main_with_card"},on:{"update:show":function($event){return _vm.$set(_vm.showDialog, "slider", $event)}}},[_c('VRow',_vm._l((_vm.protectedConsumptionTablesByPoint),function(ref,i){
      var caption = ref.caption;
      var items = ref.items;
return _c('VCol',{key:i},[_c('ConsumptionTableByPoint',{attrs:{"caption":caption,"consumptionByHourList":items}})],1)}),1)],1),_c('XNotice',{attrs:{"show":_vm.showNotice}},[_vm._v("Шаблон успешно отправлен")]),_c('InvisibleWall',{attrs:{"show":_vm.showWall},on:{"update:show":function($event){_vm.showWall=$event}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }